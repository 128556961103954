import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import H2 from "../components/H2"
import Grid from "../components/Grid"
import P from "../components/P"

const meta = {
  title: "Kontakt",
  description: `Wir sind eine kreative Webagentur aus Luzern. Auf dieser Seite erfahren Sie mehr über uns und unser Team. Kostenloses Erstgespräch vereinbaren.`,
  keywords: "webagentur, ueber uns, kontakt, biografie",
}
const WebagenturPage = () => (
  <Layout>
    <SEO
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
    />
    <Grid container spacing={0}>
      <Grid item xs={12} md={6}>
        <h2>Agentur</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <H2>
          <P></P>
        </H2>
      </Grid>
    </Grid>
  </Layout>
)

export default WebagenturPage
